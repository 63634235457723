import React from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'
import Hero from '../../components/Hero'

import Layout from '../../components/Layout'
import ShorterContent from '../../components/ShorterContent'
import Link from '../../components/Link'
import Slider from '../../components/Slider'

export default function WhyChina({ data, pageContext }) {
  const {
    title,
    slider,
    sector,
  } = data.datoCmsWhyChina

  return (
    <Layout locale={pageContext.locale}>
      <React.Fragment>
        <HelmetDatoCms>
          <title>Nordic Asia - Investment Advisory Group | About Nordic Asia</title>
        </HelmetDatoCms>


        <section className='container center-width prose'>
          <ShorterContent>
            <div 
                dangerouslySetInnerHTML={{
                  __html: title,
                  }}
              ></div>
              <Slider className="margin-top-small" content={slider} />
          </ShorterContent>
        </section>

        <section className='gray-background'>
          <div className='container center-width prose'>
              <div className='responsive-columns'>
                  {sector.slice(0,Math.ceil(sector.length/2)).map(item => 
                  <div>
                      <div className='margin-left-small margin-right-small small margin-top'
                          dangerouslySetInnerHTML={{
                              __html: item.text,
                          }}
                      ></div>
                  </div>
                  )}
              </div>
              <div className='responsive-columns'>
                  {sector.slice(Math.ceil(sector.length/2)).map(item => 
                  <div>
                      <div className='margin-left-small margin-right-small small margin-top'
                          dangerouslySetInnerHTML={{
                              __html: item.text,
                          }}
                      ></div>
                  </div>
                  )}
              </div>
              <div className='flex flex--h-center'>
                <Link to={`/about/NordicAsia`} skipLocalePrefix={true} className="btn flex--h-center margin-top-small">
                  Läs mer om Nordic Asia
                </Link>
              </div>
          </div>
        </section>
        
      </React.Fragment>
    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsWhyChina {
      title
      slider {
        text
      }
      sector {
        ... on DatoCmsHtmlText {
              text
          }
        }
    }
    datoCmsFooter {
      ...Footer
    }
  }
`
